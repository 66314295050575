<template>
  <div class="main clearfix">
    <CurrentLocation />
    <div class="box">
      <!-- <div class="ct2-sd">
      <PanelNav :title="title" :navs="navs" />
      </div> -->
    <div class="ct2-mn">
      <PanelList
        v-for="(item, index) in navs"
        :key="index"
        :data-index="index"
        :title="item.title"
        :router="item.router"
        :more="false"
        :data="item.data"
      />
    </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
import CurrentLocation from "../layout/components/CurrentLocation";
import PanelNav from "../layout/components/PanelNav";
import PanelList from "../layout/components/PanelList";

import axios from "@/api/apis";

export default {
  name: "Train",
  components: { PanelNav, PanelList, CurrentLocation },
  created() {},
  setup() {
    const state = reactive({
      title: "培训教育",
      navs: [
        {
          title: "空中课堂",
          router: "",
          child: [
            {
              title: "渝北近视防控干预",
              router: "/train/airClass/airClassYbjsfkgykt",
              data: {
                list: [],
              },
            },
          ],
          data: {
            list: [],
          },
        },
      ],
    });
    const loadArticle = () => {
      axios
        .post("/article/page", { group: 1, pageSize: 5, current: 1 })
        .then(function (data) {
          data.data.forEach((item) => {
            state.navs[0].data.list.push({
              id: item.id,
              title: item.title,
              type: item.type,
              content: item.content,
              createdTime: item.createdTime,
              router: "/news/company/details",
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    const loadPublish = () => {
      axios
        .post("https://cqgdrm.com/api/website/publish/page", {
          pageSize: 5,
          current: 1,
        })
        .then(function (data) {
          data.data.forEach((item) => {
            state.navs[1].data.list.push({
              id: item.id,
              title: item.title,
              type: 1,
              content: item.content,
              createdTime: item.createdTime,
              router: "/news/publish/details",
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      // loadArticle();
      // loadPublish();
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
</style>
